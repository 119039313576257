// 异步加载Facebook SDK
export function initFacebook() {
  window.fbAsyncInit = function () {
    FB.init({
      appId: '419184822828895',
      autoLogAppEvents: true,
      cookie: true, // Enable cookies to allow the server to access the session.
      xfbml: true, // Parse social plugins on this webpage.
      version: 'v3.3' // Use this Graph API version for this call.
    });
  };

  (function (d, s, id) {
    let js;
    let fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
}

// 加载谷歌sdk
export function gapiLoad() {
  return new Promise((resolve, reject) => {
    const id = 'google-jssdk';
    if (document.getElementById(id)) {
      resolve();
    } else {
      let js = document.createElement('script');
      js.id = id;
      js.src = 'https://apis.google.com/js/api:client.js';
      js.onload = () => {
        resolve();
      };
      document.body.appendChild(js);
    }
  });
}

// 谷歌登录
export function gapiInit(element, onSuccess, onFailure) {
  gapiLoad().then(() => {
    gapi.load('auth2', () => {
      let auth2 = gapi.auth2.init({
        client_id: '1089313489941-mqesnfntuit2d7h6h3brlgimivjvh63l.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin'
        // Request scopes in addition to 'profile' and 'email'
        // scope: 'additional_scope'
      });
      auth2.attachClickHandler(element, {}, onSuccess, onFailure);
    });
  });
}

// 异步加载Twitter
export function loadTwitter() {
  window.twttr = (function (d, s, id) {
    let js;
    let fjs = d.getElementsByTagName(s)[0];
    let t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://platform.twitter.com/widgets.js';
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function (f) {
      t._e.push(f);
    };

    return t;
  }(document, 'script', 'twitter-wjs'));
}

// 加载paypal
export function initPaypal() {
  return new Promise((resolve, reject) => {
    const id = 'paypalobjects';
    if (document.getElementById(id)) {
      resolve();
    } else {
      let js = document.createElement('script');
      js.id = id;
      js.src = 'https://www.paypalobjects.com/api/checkout.js';
      js.onload = () => {
        resolve();
      };
      document.body.appendChild(js);
    }
  });
}

// 设置微信二维码函数
export function setWxerwma () {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
  const wxElement = document.body.appendChild(s);
  const uri = `https://www.unutravel.com/login`; // 这里是你的回调uri
  wxElement.onload = () => {
    // eslint-disable-next-line no-undef
    const obj = new WxLogin({
      id: 'wx_login_container', // 需要显示的容器id
      appid: 'wx47ca200667912636', // appid wx*******
      scope: 'snsapi_login', // 网页默认即可
      redirect_uri: encodeURIComponent(uri), // 授权成功后回调的url
      state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
      style: 'black', // 提供"black"、"white"可选。二维码的样式
      href: '' // 外部css文件url，需要https
    });
    if (!obj) {
      console.error('wx-error');
    }
  };
}
