<template>
  <div>
    <form class="login-form">
      <div class="title" v-if="!isSubmit">{{$t('registered')}}</div>
      <div class="tab-nav one cl">
        <div class="item active">
          <i class="icons i-user"/>
          <span class="label">{{$t('PersonalUser')}}</span>
        </div>
      </div>
      <template v-if="!isSubmit">
        <div class="form-item">
          <div class="ipt">
            <input type="text" :placeholder="$t('Please enter your email address')" autocomplete="off" v-model.trim="userAccount"/>
          </div>
        </div>
        <div class="form-item">
          <div class="ipt">
            <input type="password" :placeholder="$t('Please enter your password')" autocomplete="off" v-model.trim="password"/>
          </div>
        </div>
        <div class="form-item">
          <div class="ipt">
            <input type="password" :placeholder="$t('Please enter your password again')" autocomplete="off" v-model.trim="confirmPassword"/>
          </div>
        </div>
        <div class="form-item">
          <div class="ipt">
            <input type="text" autocomplete="off" :placeholder="$t('Please enter verification code')" v-model.trim="captcha"/>
          </div>
          <button class="btnCaptchaName" :disabled="disableCaptcha" @click="getCaptcha">{{btnCaptchaName}}</button>
        </div>
        <p class="p1">{{$t('Already have an account?')}}
          <a href="javascript:;" @click="$router.go(-1)">{{$t('Log in immediately')}}</a>
        </p>
        <a class="btn-submit" href="javascript:;" :disabled="disableRegister"
           @click="registered">{{$t('registered')}}</a>
      </template>
      <div class="submit-success" v-else>
        <div class="fcs1">免费注册</div>
        <div class="fcs2">只差一步</div>
        <div class="fcs3">感谢你的注册，验证邮件已寄至邮箱<br/>4581732**@qq.com<br/>请前往电邮信箱，启动会员验证</div>
      </div>
      <third-party/>
    </form>
  </div>
</template>
<script>
  import ThirdParty from './components/ThirdParty';
  import sendAuthCode from '../../mixins/sendAuthCode';
  import {isEmail} from '../../utils/validate';
  import {register} from '../../api/user';

  export default {
    name: 'Registered',
    data() {
      return {
        isSubmit: false,
        userAccount: '',
        password: '',
        confirmPassword: '',
        captcha: ''
      };
    },
    computed: {
      disableCaptcha: function () {
        return this.duration > 0 || !isEmail(this.userAccount);
      },
      disableRegister: function () {
        return !isEmail(this.userAccount) || !this.password || !this.captcha;
      }
    },
    components: {ThirdParty},
    mixins: [sendAuthCode],
    mounted() {
      document.querySelector('html').classList.add('login-main');
    },
    created() {
    },
    destroyed() {
      document.querySelector('html').classList.remove('login-main');
    },
    methods: {
      // 注册
      registered() {
        const data = {
          userType: 'personal',
          userAccount: this.userAccount,
          password: this.password,
          captcha: this.captcha
        };

        if (data.password.length < 6) {
          this.$message.warning('密碼長度至少6位');
          return false;
        }
        if (data.password !== this.confirmPassword) {
          this.$message.warning('兩次輸入的密碼不一致');
          return false;
        }

        const loading = this.$loading({text: 'Loading'});
        register(data).then(res => {
          loading.close();
          this.$message.success('註冊成功');
          this.$router.go(-1);
        }).catch(() => {
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  @import "style";
  .login-form{
max-width:100%;  }
</style>
