<template>
  <div class="third-party">
    <div class="title">
      <span>{{$t('SignInWithADifferentAccount')}}</span>
    </div>
    <div class="links">
      <div class="item" @click="logInWithFacebook">
        <div>
          <img src="../../../assets/images/icon/facebook.png" width="40"/>
        </div>
        <div class="label">Facebook</div>
      </div>
      <div id="googleLogin" class="item">
        <div>
          <img src="../../../assets/images/icon/google.png" width="40"/>
        </div>
        <div class="label">Google</div>
      </div>
<!--      <div class="item" @click="wechatLogin">-->
<!--        <div>-->
<!--          <img src="../../../assets/images/icon/wechat.png" width="40"/>-->
<!--        </div>-->
<!--        <div class="label">{{$t('wechat')}}</div>-->
<!--      </div>-->
      <div id="wx_login_container" v-if="isShow"></div>
    </div>
  </div>
</template>
<script>
import {gapiInit, setWxerwma} from '../../../utils/thirdParty';
import {render} from '@/api/user';

  export default {
    name: 'ThirdParty',
    data() {
      return {
        isShow: false
      };
    },
    async mounted() {
      await this.loadFacebookSDK(document, 'script', 'facebook-jssdk');
      this.initFacebook();
    },
    created() {
      this.$nextTick(() => {
        gapiInit('googleLogin', this.googleLoginSuccess, () => {
        });
        setWxerwma();
      });
      // 判斷是否有微信回調回來的code參數
        if (this.$route.query.code) {
          let data = {
            code: this.$route.query.code,
            state: this.$route.query.state
          };
          render(data).then(res => {
            console.log(res);
            let {value} = res;
            this.$emit('login', 'wechat', value.id, value.accessToken);
          });
        }
    },
    methods: {
      async logInWithFacebook() {
        window.FB.login(response => {
          let grantedScopes = String(response.authResponse.grantedScopes);
          // Check if user granted email address
          if (!grantedScopes.includes('email')) {
            this.$message.error('未有授權獲取電郵，因此未能使用Facebook登入！');
          } else {
            this.$emit('login', 'facebook', response.authResponse.userID, response.authResponse.accessToken);
          }
        }, {auth_type: 'rerequest', scope: 'email', return_scopes: true});
        return false;
      },
      async initFacebook() {
        window.fbAsyncInit = function() {
          window.FB.init({
            appId: '419184822828895', // You will need to change this
            cookie: true, // This is important, it's not enabled by default
            version: 'v9.0'
          });
        };
      },
      async loadFacebookSDK(d, s, id) {
        var js;
          var fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      },
      // google登录成功回调
      googleLoginSuccess(googleUser) {
        const profile = googleUser.getBasicProfile();
        let accessToken = null;
        // Get the access_token from googleUser model
        // The structure googleUser model is dynamic, the location of label of 'access_token' is not static
        // So we need to traversel the whole tree to get the location of 'access_token'
        // This solution is provided by https://stackoverflow.com/questions/722668/traverse-all-the-nodes-of-a-json-object-tree-with-javascript
        function traverse(o, func) {
            for (var i in o) {
                func.apply(this, [i, o[i]]);
                if (o[i] !== null && typeof (o[i]) === 'object') {
                    // going one step down in the object tree!!
                    traverse(o[i], func);
                }
            }
        }
        traverse(googleUser, (key, value) => { if (key === 'access_token') { accessToken = value; } });
        this.$emit('login', 'google', profile.getId(), accessToken);
      },
      wechatLogin() {
        setWxerwma();
        this.isShow = true;
      }
    }
  };
</script>
<style scoped lang="less">
  .third-party{
    padding:40px 0 30px;
    .title{
      text-align:center;color:#a6a6a6;font-size:18px;border-top:1px dashed #e6e5e5;
      span{display:inline-block;padding:0 10px;background-color:#fff;transform:translateY(-50%);}
    }
    .links{
      margin-top:24px;text-align:center;font-size:0;
      .item{display:inline-block;width:126px;cursor:pointer;}
      .label{margin-top:5px;color:#000;font-size:16px;}
    }
  }
</style>
