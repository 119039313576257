import {getCaptcha} from '../api/user';

const DURATION = 120;

export default {
  data () {
    return {
      duration: 0,
      timer: null,
      smsType: 'register'
    };
  },
  computed: {
    btnCaptchaName: function () {
      return this.duration > 0 ? `${this.duration}s` : this.$t('GetVerificationCode');
    }
  },
  destroyed () {
    this.clearTimmer();
  },
  methods: {
    // 获取验证码
    getCaptcha () {
      this.countdown();
      getCaptcha({userAccount: this.userAccount, type: this.smsType}).then(res => {
        if (res.code !== 200) {
          this.clearTimmer();
        }
      }).catch(() => {
        this.clearTimmer();
      });
    },
    // 倒计时
    countdown () {
      this.duration = DURATION;
      this.timer = setInterval(() => {
        this.duration -= 1;
        if (this.duration <= 0) {
          this.clearTimmer();
        }
      }, 1000);
    },
    clearTimmer () {
      this.duration = 0;
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    }
  }
};
